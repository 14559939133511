import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Root from "./routes/Root";
import Factura from "./routes/Factura";
import Main from "./routes/Main";
import Terms from "./components/legal/Terms";
import Policy from "./components/legal/Policy";
import OK from "./components/payment/OK";
import Cancel from "./components/payment/Cancel";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {index: true, element: <Main />},
            {
                path: "factura",
                children: [
                    {
                        index: true,
                        element: <Factura />,
                    },
                    {
                        path: "ok",
                        element: <OK />,
                    },
                    {
                        path: "cancel",
                        element: <Cancel />,
                    },
                ]
            },
            {
                path: "terms",
                element: <Terms />,
            },
            {
                path: "policy",
                element: <Policy />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
