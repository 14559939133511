import topImage from "../../assets/top_image.png";

const HeroSlider = () => {
    return (
        <div className="hero-slider-2 owl-carousel owl-theme">
            <div className="single-slide slide-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="hero-contents bg-cover"
                                style={{backgroundImage: "url(" + topImage + ")"}}
                            >
                                <div
                                    className="slide-title wow fadeInLeft animated"
                                    data-wow-duration="1.3s"
                                >
                                    <span className="aa">Administrarea Asociațillor</span>
                                </div>
                                <div
                                    className="slide-title wow fadeInLeft animated"
                                    data-wow-duration="1.8s"
                                >
                                    <span>Gestionarea Condominiilor</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div
                                        className="pe-lg-5 wow fadeInLeft animated"
                                        data-wow-duration="1.8s"
                                    >
                                        <div
                                            className="slide-title wow fadeInLeft animated"
                                            data-wow-duration="2.2s"
                                        >
                                            <span className="aa">Digitalizarea Serviciilor Comunale</span>
                                        </div>
                                        <div
                                            className="slide-title wow fadeInLeft animated"
                                            data-wow-duration="2.8s"
                                        >
                                            <span>Intermediar la Decontări</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"hero-btns"}>
                                    <div className="side-box">
                                        <div
                                            className="btn-wepper btn-2 wow fadeInUp"
                                            data-wow-duration="1s"
                                            data-wow-delay="0.6s"
                                        >
                                            <a href="/factura" className="theme-btns double-btn" target="_blank">
                                                <span>Prezintă indicii</span>
                                                <span>Achită online</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="btn-wepper btn-1 wow fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="0.6s"
                                    >
                                        <a
                                            href="#servicii_section"
                                            className="btn-wepper bg-bth "
                                        >
                                            Serviciile Noastre
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSlider;