import {Row, Col, Container} from "react-bootstrap";

function DialogModal({
  show,
  handleClose,
  handleConfirm,
  yesText,
  noText,
  question,
  handleDecline,
}) {
  if (!show) return null;
  return (
    <>
      <div className="fade modal-backdrop show"></div>
      <div
        role="dialog"
        aria-modal="true"
        className="fade modal show"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            {/* <div className="modal-header">
              <div className="modal-title h4">
                <h4 className="modal-question">{question}</h4>
              </div>
              <button
                type="button"
                onClick={handleClose}
                className="btn-close"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body">
              <div className="custom-modal-close">
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn-close"
                  aria-label="Close"
                ></button>
              </div>
              <Container>
                <Row>
                  <Col>
                    <div className="custom-modal-title">
                      {/* <i className="fa-solid fa-question"></i> */}
                      <h4 className="modal-question">{question}</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <button
                      className="xs-btn mb-3"
                      variant="secondary"
                      onClick={handleDecline}
                    >
                      {noText}
                    </button>
                  </Col>
                  <Col md={6}>
                    <button
                      className="xs-reverse-btn"
                      variant="primary"
                      onClick={handleConfirm}
                    >
                      {yesText}
                    </button>
                  </Col>
                </Row>
              </Container>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DialogModal;
