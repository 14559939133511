import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Container, Col, Row, Image } from "react-bootstrap";
import "./payment.css";
import ImageSucces from "../../assets/lottie/accept.json";
import { Player } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import { API_URL } from "../../constants";
import * as dayjs from "dayjs";

const OK = () => {
  const [data, setData] = useState({});
  const [amount, setAmount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  async function getInvoice() {
    try {
      const res = await axios.get(
        API_URL + `payment/external/${searchParams.get("id")}`
      );
      if (res.data) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    if (data && data?.Plati && data?.Plati?.length) getAmount();
  }, [data]);

  function getAmount() {
    let sum = 0;
    for (const doc of data?.Plati) {
      sum += parseFloat(doc.SUMA);
    }
    setAmount(sum);
  }

  return (
    <Container className="payment-block">
      <div className="payment-title-block d-flex align-items-center mb-4">
        <Link to={"/"} className="back-to-factura-btn">
          <i className="fa-solid fa-chevron-left"></i>
        </Link>
        <div className="w-100">
          <h2 className="text-center success-text-style">
            Plata a fost efectuată cu succes!
          </h2>
        </div>
      </div>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="payment-user-info d-flex mb-4 justify-content-between">
            <div>
              <p>
                Proprietar:{" "}
                <strong>{data?.Plati && data?.Plati[0]?.USERINFO}</strong>
              </p>
              <p>
                Codul individual:{" "}
                <strong>{data?.Plati && data?.Plati[0]?.CODID}</strong>
              </p>
            </div>
            <div>
              <p>
                Nr. tranzactiei: <strong>{data?.paynet_payment_id}</strong>
              </p>
              <p>
                Data:{" "}
                <strong>
                  {dayjs(data?.status_date ? data?.status_date : data.createdAt).format("DD-MM-YYYY")}
                </strong>
              </p>
            </div>
            <div>
              <p>
                Total: <strong>{parseFloat(amount).toFixed(2)} lei</strong>
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="payment-img text-center">
            <Player
              autoplay
              loop
              src={ImageSucces}
              style={{ height: "400px", width: "auto" }}
            ></Player>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OK;
