import "./legal.css";
const Policy = () => {

    return (
        <div className='container policy-pages-style'>
            <div className="block-contents text-center">
                <div className="section-title about-title">
                    <h2>Politica de Confidențialitate</h2>
                </div>
            </div>

            <p>
                Profadmin SRL, in calitate de autor/proprietar/administrator/utilizator al website-ului <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> respecta caracterul privat si securitatea informatiei furnizate de catre dumneavoastra atunci cand utilizati acest website, inclusiv a datelor cu caracter personal, scop in care a creat si aplica prezenta politica de confidentialitate.
                Accesul/vizitarea acestui website de catre dumneavoastra se supune termenilor si conditiilor de utilizare si politicii de confidentialitate, implica acceptul explicit al dumneavoastra cu privire la acestea si reprezinta intreaga intelegere (contractul) dintre parti.
            </p>
            <p>
                Profadmin SRL isi rezerva dreptul de a schimba si actualiza in orice moment continutul website-ului <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a>, precum si Politica de confidentialitate si Termenii si conditiile de folosire, fara nici un fel de notificare prealabila. De aceea, va rugam sa vizitati periodic aceasta sectiune pentru a verifica termenii si conditiile pe care ati agreat sa le respectati.
            </p>
            <p>
                Termenii si conditiile prezentate sunt valabile si se aplica pentru toate website-urile din domeniul <a href="/" className="go-to-factura-link" target="_blank"><i>profadmin.md</i></a> si pentru care Profadmin SRL are calitatea de autor/proprietar/administrator/utilizator. Toate aceste website-uri (asa cum exista ele in prezent sau vor fi ulterior implementate) precum si website-ul <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> vor fi denumite generic, in mod individual sau colectiv Site si/sau Site-uri in cele ce urmeaza.
            </p>
            <p>
                In cadrul prezentului document, cuvintele la singular includ pluralul, cuvintele la plural includ singularul, cuvintele care denota genul masculin includ femininul, si cuvintele care denota genul feminin includ masculinul.
            </p>

            <h6>- DEFINITII</h6>
            <p>
                1. date cu caracter personal - orice informatii referitoare la o persoana fizica identificata sau identificabila; o persoana identificabila este acea persoana care poate fi identificata, direct sau indirect, in mod particular prin referire la un numar de identificare ori la unul sau la mai multi factori specifici identitatii sale fizice, fiziologice, psihice, economice, culturale sau sociale;<br />
                2. prelucrarea datelor cu caracter personal - orice operatiune sau set de operatiuni care se efectueaza asupra datelor cu caracter personal, prin mijloace automate sau neautomate, cum ar fi colectarea, inregistrarea, organizarea, stocarea, adaptarea ori modificarea, extragerea, consultarea, utilizarea, dezvaluirea catre terti prin transmitere, diseminare sau in orice alt mod, alaturarea ori combinarea, blocarea, stergerea sau distrugerea;<br />
                3. stocarea - pastrarea pe orice fel de suport a datelor cu caracter personal culese;<br />
                4. sistem de evidenta a datelor cu caracter personal - orice structura organizata de date cu caracter personal, accesibila potrivit unor criterii determinate, indiferent daca aceasta structura este organizata in mod centralizat ori descentralizat sau este repartizata dupa criterii functionale ori geografice;<br />
                5. operator - orice persoana fizica sau juridica, de drept privat ori de drept public, inclusiv autoritatile publice, institutiile si structurile teritoriale ale acestora, care stabileste scopul si mijloacele de prelucrare a datelor cu caracter personal;<br />
                6. tert - orice persoana fizica sau juridica, de drept privat ori de drept public, inclusiv autoritatile publice, institutiile si structurile teritoriale ale acestora, alta decat persoana vizata, operatorul ori persoana imputernicita sau persoanele care, sub autoritatea directa a operatorului sau a persoanei imputernicite, sunt autorizate sa prelucreze date;<br />
                7. destinatar - orice persoana fizica sau juridica, de drept privat ori de drept public, inclusiv autoritatile publice, institutiile si structurile teritoriale ale acestora, careia ii sunt dezvaluite date, indiferent daca este sau nu tert; autoritatile publice carora li se comunica date in cadrul unei competente speciale de ancheta nu vor fi considerate destinatari;<br />
                8. date anonime - date care, datorita originii sau modalitatii specifice de prelucrare, nu pot fi asociate cu o persoana identificata sau identificabila;<br />
                9. date de tip "business contact" - date care includ numele, functia, adresa de business, numarul de telefon sau adresa e-mail a unui angajat al unei organizatii, in aceasta calitate. Datele tip "business contact" nu intra in categoria datelor cu caracter personal;<br />
                10. date statistice - date care au fost obtinute ca urmare a prelucrarii de catre operator a datelor cu caracter personal dar care nu pot fi folosite la identificarea unei persoane si sunt folosite exclusiv pentru scopuri statistice si/sau de informare, promovare.<br />
            </p>


            <h6>- COLECTAREA DE DATE CU CARACTER PERSONAL</h6>
            <p>
                Profadmin SRL poate colecta de la dumneavoastra date cu caracter personal, dar numai cu acordul dumneavoastra si daca le furnizati voluntar.
                Formularul de inregistrare prezentat pe site-ul <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> cere informatii prin care puteti fi identificat sau prin care va putem contacta.
                Colectarea de date cu caracter personal pe Site-ul <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> implica:
                Formularele de plata online prezentate dumneavoastra atunci cand efectuati o tranzactie eCommerce pe unul din website-urile comerciantilor web care folosesc solutia profadmin vor trebui completate cu toate informatiile solicitate si marcate a fi obligatorii, inclusiv datele cu caracter personal.
                Folosim datele cu caracter personal colectate pe Site-ul <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> in urmatoarele scopuri:<br />
                * facturarii produselor/serviciilor comandate de catre dumneavoastra;<br />
                * livrarii acestora;<br />
                * efectuarii platii online;<br />
                * asigurarii securitatii tranzactiei eCommerce/platii online prin aplicarea de filtre antifrauda.<br />
            </p>
            <p>
                Datele cu caracter personal vor putea fi dezvaluite fara consimtamantul dumneavoastra in caz de litigii/dispute privind fraudele la plata catre urmatorii destinatari: Bancile implicate in platile online, Organismele Internationale de Carduri si Furnizori de servicii/produse, respectiv conform prevederilor Legii, catre institutiile abilitate.
                Prin continuarea tranzactiei eCommerce din cadrul paginii de plata, prin intermediul Site-ului <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a>, va exprimati expres consimtamantul cu privire la colectarea sau prelucrarea datelor cu caracter personal in sistemul de evidenta informatizat de catre Profadmin SRL.
                In ceea ce priveste datele ce ar putea fi colectate cu ocazia navigarii pe <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a>, folosim aceste date cu caracter personal colectate in urmatoarele scopuri:<br />
                * Pentru a ne asigura ca pagina de web este relevanta nevoilor dumneavoastra.<br />
                * Pentru a livra servicii precum buletinele de stiri, evenimentele sau produsele pe care le-ati cerut sau achizitionat<br />
                * Pentru a ne ajuta sa cream si sa publicam continutul cel mai relevant pentru dvs.<br />
                * Pentru a asigura accesul la sectiuni cu acces limitat ale site-ului.<br />
            </p>
            <p>
                Profadmin SRL colecteaza informatii si le prelucreaza in date statistice despre ce pagini accesati in interiorul Site-urilor, inclusiv adresa IP de la care Site-ul este vizitat. Profadmin SRL poate folosi in cadrul Site-urilor cookie-uri. Un cookie este un fisier pe care serverele noastre il trimit computerului dumneavoastra si pe care computerul dumneavoastra il trimite inapoi de fiecare data cand accesati o pagina pe Site-ul nostru.<br />
                Cookie-urile sunt folosite in urmatoarele scopuri:<br />
                * Pentru a imbunatati securitatea accesului.<br />
                * Pentru a imbunatati uzabilitatea si a va furniza un serviciu cat mai bun.
            </p>
            <p>
                Majoritatea browserelor de internet accepta automat cookie-urile. Cu toate acestea, puteti solicita browserului utilizat, prin editarea optiunilor acestuia, sa opreasca acceptarea de cookie-uri si sa va avertizeze inainte de a accepta un cookie din site-urile pe care le vizitati. Daca decideti sa nu ne acceptati cookie-urile, veti putea totusi sa accesati sectiuni ale Site-ului nostru care sunt disponibile publicului general, dar nu veti putea vizualiza anumite informatii si caracteristici de pe Site-ul nostru, concepute pentru a imbunătăţi experienţa dumneavoastră pe Site-ul nostru.
                In diferite sectiuni din Site se gasesc linkuri pentru a ne trimite un mesaj email. Adresa dumneavoastra de email este folosita pentru a va trimite un raspuns si mesajul dumneavoastra este retinut pentru referinte viitoare in cazul in care decideti sa ne contactati din nou. Adresa dumneavoastra de e-mail si numele furnizat, Profadmin SRL le va putea utiliza, inclusiv in a le furniza unor terti, avand acceptul dumneavoastra.
                Profadmin SRL poate dezvalui datele cu caracter personal catre terti daca acest lucru este cerut de lege sau in cazurile de buna credinta in care aceste actiuni sunt necesare pentru: (a) conformarea la rigorile legii; (b) protejarea si apararea drepturilor de proprietate ale Profadmin SRL si ale site-urilor sale web, sau (c) actionarea in circumstante de urgenta pentru protejarea sigurantei personale a angajatilor, utilizatorilor de produse sau servicii, sau a persoanelor publice.
                Atunci cand suntem solicitati sa furnizam date cu caracter personal sau ni se permite acest lucru, vor fi dezvaluite doar acele date necesare indeplinirii obiectului solicitarii.
                Profadmin SRL nu vinde, schimba sau inchiriaza date cu caracter personal catre terti.
            </p>

            <h6>- UTILIZAREA DATELOR STATISTICE SI DATELOR ANONIME</h6>
            <p>
                Profadmin SRL poate folosi datele statistice sau datele anonime rezultate, in urmatoarele scopuri:<br />
                * realizarii de analize/rapoarte;<br />
                * realizarii de informari;<br />
                * publicarii, promovarii, ofertarii produselor/serviciilor oferite de catre Profadmin SRL.
            </p>

            <h6>- SECURITATEA DATELOR COLECTATE</h6>
            <p>
                Profadmin SRL utilizeaza metode si tehnologii de securitate, impreuna cu politici aplicate salariatilor si proceduri de lucru , inclusiv de control si audit, pentru a proteja datele cu caracter personal colectate conform prevederior legale in vigoare.
                Colectarea datelor cu caracter personal se face utilizand o conexiune SSL criptata, folosindu-se un certificat digital emis de www.globehosting.com, o companie Globe Hosting, Inc.
                Serverele pe care Site-urile sunt gazduite sunt protejate de acces fizic si la distanta limitat, fiind instalate in incinte adecvate tehnic si din punctul de vedere al securitatii. Depunem toate eforturile rezonabile, justificate comercial pentru a va proteja datele cu caracter personal colectate, analizam noile tehnologii in domeniu si, atunci si daca este cazul, le aplicam in vederea upgrade-ului sistemelor noastre de securitate. De asemenea, infrastructura hardware si software utilizata de catre solutia profadmin, este periodic analizata din punctul de vedere al securitatii.
            </p>

            <h6>- DREPTURILE CU PRIVIRE LA DATELE COLECTATE SI PRELUCRATE</h6>
            <p>
                In legatura cu prelucrarea datelor cu caracter personal si in baza consitiilor specificate in legea nr.133 din 08.07.2011, va puteti exercita oricare dintre urmatoarele drepturi:<br />
                1. dreptul de a va accesa datele cu caracter personal;<br />
                2. dreptul de a solicita rectificarea, stergerea sau blocarea corespunzatoare a datelor cu caracter personal;<br />
                3. dreptul de opozitie;<br />
                4. dreptul de a nu fi supus unei decizii individuale automate (decizii automate bazate pe prelucrarea datelor prin mijloace automate, in scopul de a evalua anumite aspecte personale);<br />
                5. dreptul de a face plangere la autoritatea de supraveghere, precum si dreptul de a va adresa instantei de judecata in legatura cu orice incalcare a drepturilor privind prelucrarea datelor cu caracter personal.<br />
                Toate aceste drepturi poti fi exercitate prin intermediul unei cereri scrise, datate si semnate, adresate societatii noastre.
            </p>


            <h6>- LEGATURI EXTERNE</h6>
            <p>
                Site-urile pot contine legaturi spre alte website-uri, in afara controlului Profadmin SRL, website-uri care credem ca le veti gasi utile. Daca accesati aceste linkuri veti accesa/vizita website-uri care pot avea o politica de confidentialitate diferita de <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a>.
            </p>

            <h6>- MINORI</h6>
            <p>
                Profadmin SRL nu vinde produse si/sau servicii care sa fie cumparate de catre minori. Profadmin SRL poate vinde sau incasa contravaloarea unor produse/servicii destinate minorilor, dar achizitionate de catre un adult. Daca sunteti minor si accesati un Site o puteti face doar daca sunteti sub supravegherea unui parinte sau a tutorelui legal.
            </p>


            <h6>- IMPUNEREA ACESTEI POLITICI DE CONFIDENTIALITATE</h6>
            <p>
                Daca aveti intrebari legate Politica de Confidentialitate sau doriti sa fiti informat asupra datelor cu caracter personal prelucrate de catre Profadmin SRL si care va privesc direct, va rugam sa ne contactati.
            </p>

            <h6>- TERMENI SI CONDITII DE UTILIZARE</h6>
            <p>
                Va rugam sa accesati si sectiunea Termeni si conditii de utilizare care se aplica de asemenea accesului/vizitei dumneavoastra pe Site.
            </p>


            <h6>- LEGISLATIE</h6>
            <p>
                Politica de confidentialitate se supune legislatiei din Rep. Moldova. In caz de litigiu, se va incerca mai intai o rezolvare pe cale amiabila, in termen de 30 de zile lucratoare de la inregistrarea reclamatiei la sediul Profadmin SRL. In cazul in care nu se poate ajunge la o intelegere in termenul precizat anterior, va fi considerata competenta instanta judecatoreasca din aceeasi structura administrativa cu sediul Profadmin SRL.
            </p>

            <h6>- CONSIMTAMANT EXPRES SI NEECHIVOC</h6>
            <p>
                Prin acceptarea prezentului formular de consimtamant confirmati faptul ca ati fost informat cu privire la datele cu caracter personal referitoare la dumneavoastra, care urmeaza sa fie colectate, stocate, prelucrate si utilizate, si ca sunteti de acord ca aceste date cu caracter personal sa fie prelucrate si utilizate in limitele astfel specificate, pe durata de desfasurare a activitatii societatii.
            </p>

            <h6>- CONSIMTAMANT</h6>
            <p>
                Accept prezentul formular pentru a indica faptul ca: <br />
                1. am citit si am inteles in totalitate cele de mai sus, si:<br />
                2. consimt la prelucrarea si utilizarea Datelor mele cu Caracter Personal, in conformitate cu cele indicate in prezentul formular.
            </p>
            <p>
                Ultima actualizare: 13 martie 2024
            </p>

        </div>
    )
}

export default Policy;