import '@splidejs/react-splide/css';
import './aboutslide.css';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Options } from '@splidejs/splide';
import React, {useRef, useEffect} from 'react';

// function generateSlides( length = 10, sig = 0 ) {
//     return Array.from( { length } ).map( ( value, index ) => {
//         index = sig || index;
//         return {
//             src: `https://source.unsplash.com/random/800x450?sig=${ index }`,
//             alt: `Image ${ index + 1 }`,
//         };
//     } );
// }

const slidesImgArr = [
    require('../../assets/images/slider-img/image_16x9_1.png'),
    require('../../assets/images/slider-img/image_16x9_2.png'),
    require('../../assets/images/slider-img/image_16x9_3.png'),
    require('../../assets/images/slider-img/image_16x9_4.png'),
    require('../../assets/images/slider-img/image_16x9_5.png'),
    require('../../assets/images/slider-img/image_16x9_6.png'),
    require('../../assets/images/slider-img/image_16x9_7.png'),
    require('../../assets/images/slider-img/image_16x9_8.png'),
    require('../../assets/images/slider-img/image_16x9_9.png'),
    require('../../assets/images/slider-img/image_16x9_10.png'),
    require('../../assets/images/slider-img/image_16x9_11.png'),
]

const renderSlides = () => {
    return slidesImgArr.map( (slide, index) => (
        <SplideSlide key={ index }>
            <img src={ slide } alt={`Image ${ index + 1 }`}/>
        </SplideSlide>
    ) );
}
const AboutSlider = () => {
    const mainRef = useRef();
    const thumbsRef = useRef();

    const mainOptions = {
        type      : 'fade',
        rewind    : true,
        pagination: false,
        arrows    : false,
        perPage   : 1,
        perMove   : 1,
        gap       : '1rem',
        height    : '100%',
    };

    const thumbsOptions = {
        type        : 'slide',
        rewind      : true,
        gap         : '1rem',
        pagination  : false,
        fixedWidth  : 100,
        fixedHeight : 100,
        cover       : true,
        focus       : 'center',
        isNavigation: true,
    };
    useEffect(() => {
        if ( mainRef.current && thumbsRef.current && thumbsRef.current.splide ) {
           mainRef.current.sync( thumbsRef.current.splide );
        }
    }, []);

    return (
        <div className="wrapper">
            <Splide
                options={ mainOptions }
                ref={ mainRef }
                aria-labelledby="thumbnail-slider-example"
                className='custom-slide-style'
            >
                { renderSlides() }
            </Splide>

            <Splide
                options={ thumbsOptions }
                ref={ thumbsRef }
                aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
            >
                { renderSlides() }
            </Splide>
        </div>
    )
}

export default AboutSlider;
