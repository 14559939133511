import "./legal.css";
const Terms = () => {

    return (
        <div className='container policy-pages-style'>
            <div className="block-contents text-center">
                <div className="section-title about-title">
                    <h2>Termeni și condiții</h2>
                </div>
            </div>
            <p>
                Bine ati venit pe site-ul <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a>!
            </p>
            <p>
                Va rugam sa cititi cu atentie termenii si conditiile de utilizare ale acestui website prezentate mai jos. Accesul/vizitarea acestui website de catre dumneavoastra se supune Termenilor si conditiilor de utilizare, implica acceptul explicit al dumneavoastra cu privire la acestea si reprezinta intreaga intelegere (contractul) dintre parti.
                Profadmin SRL, in calitate de autor/proprietar/administrator al website-ului <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> isi rezerva dreptul de a schimba si actualiza in orice moment continutul acestui website, precum si Politica de confidentialitate si Termenii si conditiile de folosire, fara nici un fel de notificare prealabila. De aceea, va rugam sa vizitati periodic aceasta sectiune pentru a verifica termenii si conditiile pe care ati agreat sa le respectati.
            </p>

            <h6>- APLICABILITATE</h6>
            <p>
                Termenii si conditiile prezentate sunt valabile si se aplica pentru toate website-urile din domeniul <a href="/" className="go-to-factura-link" target="_blank"><i>profadmin.md</i></a> si pentru care Profadmin SRL are calitatea de autor/proprietar/administrator. Toate aceste website-uri (asa cum exista ele in prezent sau vor fi ulterior implementate) precum si website-ul <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> vor fi denumite generic, in mod individual sau colectiv Site si/sau Site-uri in cele ce urmeaza. In cadrul prezentului document, cuvintele la singular includ pluralul, cuvintele la plural includ singularul, cuvintele care denota genul masculin includ femininul, si cuvintele care denota genul feminin includ masculinul.
            </p>

            <h6>- DREPTURI DE AUTOR</h6>
            <p>
                Profadmin SRL este autorul Site-urilor si beneficiaza de toate drepturile legale recunoscute autorilor, conform legislatiei Rep. Moldova si internationale. In lipsa altor precizari, toate materialele, incluzand imagini, ilustratii, design-uri, icoane, fotografii, clipuri audio/video, sunt proprietatea Profadmin SRL sau sunt licentiate de Profadmin SRL pentru a fi folosite.
            </p>

            <h6>- LICENTE DE UTILIZARE</h6>
            <p>
                Profadmin SRL va acorda o licenta limitata pentru a accesa/vizita Site-ul, fara posibilitatea de a-l modifica in lipsa unui acord din partea Profadmin SRL. Aceasta licenta nu include: revanzarea sau utilizarea in scopuri comerciale a informatiilor prezentate pe Site; download-ul sau copierea informatiilor legate de conturi utilizator; utilizarea tehnicilor tip data mining, robotilor sau tehnologiilor similare care permit extragerea si analiza datelor.
                Profadmin SRL va acorda o licenta limitata, revocabila si ne-exclusiva de a va crea un hyperlink catre pagina home a Site-ului atata vreme cat acest link nu descrie <a href="/" className="go-to-factura-link" target="_blank"><i>profadmin.md</i></a> sau un partener/afiliat al acestuia de o maniera falsa, gresita sau jignitoare. Licenta acordata nu va permite utilizarea in cadrul acestui hyperlink a marcii <a href="/" className="go-to-factura-link" target="_blank"><i>profadmin.md</i></a> fara acordul prealabil scris al Profadmin SRL.
                Continutul Site-urilor, integral si/sau partial, nu poate fi reprodus, duplicat, copiat, revandut, vizitat sau exploatat in scopuri comerciale fara acceptul scris prealabil al Profadmin SRL.
            </p>

            <h6>- MARCI</h6>
            <p>
                Marca profadmin este o marca inregistrata si este detinuta de catre Profadmin SRL. Domeniul <a href="/" className="go-to-factura-link" target="_blank"><i>profadmin.md</i></a> este detinut de catre Profadmin SRL. Utilizarea acestei marci, a domeniului sau a numelui profadmin, in forma directa sau "ascunsa" (de tipul, dar nu limitat la, meta taguri sau alte tehnici de indexare, cautare web) fara permisiunea prealabila scrisa a Profadmin SRL este interzisa si se pedepseste conform legii. Utilizarea neautorizata a acestora revoca imediat licentele acordate de catre Profadmin SRL, conform cu cele de mai sus.
            </p>

            <h6>- CONDITII DE UTILIZARE. GARANTII.</h6>
            <p>
                Site-ul este furnizat de catre Profadmin SRL pe o baza "asa cum este" si "atat cat este disponibil" . Profadmin SRL nu garanteaza ca Site-ul, serverele pe care este gazduit, sau e-mailurile trimise de la <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> sunt fara virusi sau alte componente informatice cu caracter potential daunator. Utilizatorul foloseste Site-ul pe riscul propriu, Profadmin SRL fiind liber de orice raspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea sau accesarea/vizitarea Site-ului sau ca urmare a utilizarii informatiilor de pe Site. Profadmin SRL nu este raspunzatoare de erorile sau omisiunile care pot interveni in redactarea sau prezentarea materialelor de pe Site. Profadmin SRL nu acorda nici un fel de garantie pentru continutul si utilizarea acestui Site. Informatiile publicate corespund realitatii la momentul inscrierii lor pe Site sau al actualizarii diverselor pagini ale Site-ului.
                Serviciile si produsele prezentate pe Site-uri nu se constituie intr-o oferta de niciun fel, fiind prezentate cu titlu pur informativ.
            </p>

            <h6>- SECURITATE</h6>
            <p>
                Daca utilizati Site-ul sunteti raspunzator pentru asigurarea confidentialitatii datelor privitoare la contul dumneavoastra de acces, cum ar fi numele de utilizator si parola si sunteti de acord sa va asumati intreaga responsabilitate pentru activitatile/actiunile desfasurate pe Site cu contul si parola dumneavoastra. In cazul in care suspectati ca, din diverse motive imputabile sau nu dumneavoastra, confidentialitatea acestor date de acces a fost compromisa, veti notifica imediat profadmin pentru a bloca accesul la cont si a va genera cat mai rapid un nou set.
                Efectuarea de operatiuni neautorizate asupra acestui site si tentativa de efectuare a acestora, incluzand fara a se limita la: utilizarea abuziva, utilizarea frauduloasa, accesul neautorizat, modificarea, copierea de informatii in vederea comercializarii lor, blocarea accesului, samd, vor fi pedepsite conform legii.
            </p>

            <h6>- COMUNICARI</h6>
            <p>
                Utilizatorii Site-ului pot trimite comentarii, note, sugestii, idei sau observatii cu privire la continutul si structura Site-ului, respectiv pot adresa intrebari atata timp cat continutul acestora nu este ilegal, obscen, amenintator, defaimator, care sa afecteze viata privata, drepturile de proprietate intelectuala, ale Profadmin SRL (profadmin) sau ale unor terte parti, si care nu contin virusi, deruleaza campanii politice/electorale, solicitari comerciale, scrisori in lant, mailinguri de masa, sau orice alta forma de spam. Nu puteti folosi o adresa de e-mail falsa sau sa impersonati o alta persoana sau entitate. Profadmin SRL isi rezerva dreptul, dar nu si obligatia, de a indeparta sau edita o astfel de informatie transmisa.
                Prin trimiterea unei astfel de informatii, daca nu este altfel specificat, acordati Profadmin SRL si partenerilor/afiliatilor sai licenta/dreptul gratuit, ne-exclusiv, perpetuu, irevocabil si sub-licentiabil de a utiliza, reproduce, modifica, adapta, publica, traduce, deriva, distribui sau a le afisa pe orice fel de medii, in toata lumea. Acordati dreptul Profadmin SRL si partenerilor/afiliatilor sai de a utiliza numele pe care l-ati trimis in conjunctie cu informatia, daca acestia vor decide astfel. Declarati si garantati ca detineti sau controlati toate drepturile cu privire la informatiile transmise, ca acestea sunt corecte si ca utilizarea acestora nu intra in contradictie cu Termenii si conditiile de utilizare, ca nu vor cauza daune catre o terta parte si ca veti despagubi integral Profadmin SRL sau partenerii/afiliatii sai pentru toate cererile rezultate din informatia astfel transmisa.
            </p>

            <h6>- MINORI</h6>
            <p>
                Profadmin SRL nu vinde produse si/sau servicii care sa fie cumparate de catre minori. Profadmin SRL poate vinde sau incasa contravaloarea unor produse/servicii destinate minorilor, dar achizitionate de catre un adult. Daca sunteti minor si accesati un Site o puteti face doar daca sunteti sub supravegherea unui parinte sau a tutorelui legal.
            </p>

            <h6>- DREPTURI DE PROPRIETATE INTELECTUALA</h6>
            <p>
                Profadmin SRL respecta drepturile intelectuale ale altora. Daca considerati ca munca dumneavoastra a fost copiata de o maniera care sa incalce dreptul de proprietate intelectuala, va rugam adresati Profadmin SRL o notificare care sa cuprinda:
            </p>
            <p>
                * semnatura fizica sau electronica a persoanei autorizate sa reprezinte pe posesorul dreptului de proprietate;
                * o descriere a materialelor protejate de drepturi care apreciati ca au fost incalcate;
                * o indicare a locatiei pe Site unde apreciati ca aceste drepturi au fost incalcate;
                adresa, numarul de telefon si adresa e-mail a dumneavoastra;
                * o declaratie pe proprie raspundere conform careia sunteti convins ca utilizarea in disputa nu a fost autorizata de detinatorul drepturilor, ale imputernicitului sau ale legii;
                * o declaratie notariala pe proprie raspundere conform careia actionati cu buna credinta, ca notificarea este completa si corecta si ca sunteti detinatorul drepturilor sau actionati in numele sau.
            </p>

            <h6>- POLITICA DE CONFIDENTIALITATE</h6>
            <p>
                Va rugam sa accesati si sectiunea Politica de confidentialitate care se aplica de asemenea accesului/vizitei dumneavoastra pe Site.
            </p>

            <h6>- LEGISLATIE</h6>
            <p>
                Termenii si conditiile de folosire se supun legislatiei din Rep.Moldova. In caz de litigiu, se va incerca mai intai o rezolvare pe cale amiabila, in termen de 30 de zile lucratoare de la inregistrarea reclamatiei la sediul profadmin In cazul in care nu se poate ajunge la o intelegere in termenul precizat anterior, va fi considerata competenta instanta judecatoreasca din aceeasi structura administrativa cu sediul profadmin.
            </p>
            <p>
                Ultima actualizare: 13 Martie 2024
            </p>
        </div>
    )
}

export default Terms;