import './assets/css/icons.css'
import './assets/css/animate.css'
import './assets/css/magnific-popup.css'
import './assets/css/mk_charts.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/meanmenu.css'
import './assets/css/owl.theme.css'
import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import './App.css';

import topLogo from "./assets/top_logo.svg"
import bottomLogo from "./assets/bottom_logo.svg"
import icon_administration from "./assets/icon_administration.svg"
import icon_anunturi from "./assets/icon_anunturi.svg"
import icon_articole from "./assets/icon_articole.svg"
import icon_financial from "./assets/icon_financial.svg"
import icon_law from "./assets/icon_law.svg"
import icon_legislatie from "./assets/icon_legislatie.svg"
import icon_suport from "./assets/icon_suport.svg"
import illustration from "./assets/illustration.png"
import topImage from "./assets/top_image.png"


import AboutSlider from "./components/about-slider/AboutSlider";

function App() {
  return (
    <>
      <header className="header-wrap header-1 header-5">
        <div className="container d-flex justify-content-between align-items-center p-0">
          <div className="logo">
            <a href="#">
              <img src={topLogo} alt="logo" />
            </a>
          </div>
          <div className="header-right-area d-flex">
            <div className="main-menu d-none d-xl-block">
              <nav id="mobile-menu">
                <ul>
                  <li><a href="#">Despre noi</a></li>
                  <li><a href="#">Servicii</a></li>
                  <li><a href="#">Facturare</a></li>
                  <li><a href="#">Info utile</a></li>
                  <li><a href="#">Intrebari frecvente</a></li>
                  <li><a href="#">Contacte</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <section className="hero-wrapper hero-2">
        <div className="hero-slider-2 owl-carousel owl-theme">
          <div className="single-slide slide-1" >
            <div className="container">
              <div className="row">
                <div className="col-12 pe-lg-12 col-xxl-12 col-lg-12">
                  <div className="hero-contents pe-lg-3 bg-cover" style={{'backgroundImage': 'url('+topImage+')'}}>
                    <div className="slide-title wow fadeInLeft animated" data-wow-duration="1.3s">Soluția de succes <span className={'aa'} >pentru condominiul tău</span> </div>
                    <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">
                      Scapa de stresul administrării unui condominiu și apelează la serviciile profesioniștilor.<br />
                      Prin serviciile noastre te vom ajuta să te bucuri cu adevarat de timpul tău liber.<br />
                      Fii liber, bucură-te cu adevărat de servicii complete privind administrarea imobilului.
                    </p>
                    <div className={'hero-btns'}>

                      <div className="btn-wepper btn-1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                        <a href="#" className="btn-wepper bg-bth ">Prezintă indicii</a>
                      </div>
                      <div className="btn-wepper btn-2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                        <a href="#" className="theme-btns me-sm-2 ">Achită online</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="block-contents text-center">
                <div className="section-title about-title">
                  <h2>Despre noi</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-6 col-12 pe-xl-0">
              <AboutSlider />
            </div>
            <div className="col-xl-6 mt-5 mt-lg-0 col-12 ml-3" style={{'paddingLeft': '30px'}}>
              <div className="block-contents">
                <div className="section-title">
                  <span>De ce să alegeți PROFADMIN SRL pentru administrarea imobilului dvs?</span>
                </div>
              </div>
              <p className={'pt-4'}>
                De ce să alegeți PROFADMIN SRL pentru administrarea imobilului dvs?
                Suntem o echipă dinamică, comunicabilă, transparentă de profesioniști, specializați în administrarea tuturor tipurilor de imobile (complexuri rezidențiale, vile și apartamente, clădiri și birouri) și asociații de proprietari în condominiu, formată din administratori, avocaţi, auditori și contabili, lucrători tehnici, cu experiență.
                Folosim toate mijloacele de comunicare pentru a vă împărtași din experiența noastră, dîndu-vă acces la platforma online de unde vă puteți urmari sumele de plată, facturile, plățile și chiar să participați la ședințele proprietarilor în condominiu, online, de acasa sau de la birou !
                Cu ajutorul nostru vei ști mereu ce se întîmplă cu imobilul și/sau asociația ta, indeplinim sarcinile legitime ale proprietarilor și a Consiliului de administrare a condominiului.
                Scopul nostru este de a oferi servicii de calitate, transparente și imparțiale, la cele mai înalte standarde, în strictă respectare a legislaţiei în vigoare.

              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"servicii-section section-padding"}>
        <div className={'container'}>
          <div className="row">
            <div className="col-xl-12">
              <div className="block-contents text-center">
                <div className="section-title about-title">
                  <h2>SERVICII</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-8 col-sm-12 pe-xl-0">
              <div className="block-contents">
                <div className="section-title">
                  <span>Info generala despre administrare</span>
                </div>
              </div>
              <div className={'servicii-list'}>
                <div> <span className={'icon-check'}><i className="fal fa-check"></i></span> </div>
                <p>Pentru că suntem profesioniști venim în sprijinul dvs. cu servicii complete privind administrarea imobilului, care crează confortul proprietarilor și lagalitatea activității condominiului.</p>
              </div>
              <div className={'servicii-list'}>
                <div> <span className={'icon-check'}><i className="fal fa-check"></i></span> </div>
                <p>Administrarea juridică este parte componentă a grilei de servicii prestate de către compania noastră. Clienții noștri vor avea parte de un serviciu prestat de către persoane calificate cu o experiență de ani în administrarea juridică.</p>
              </div>
              <div className={'servicii-list'}>
                <div> <span className={'icon-check'}><i className="fal fa-check"></i></span> </div>
                <p>Activitatea de administrare financiară este susţinută de persoane cu studii superioare, contabili și auditori atestați, cu experiență în activitatea de administrare imobile.
                  Ne ocupăm de tot ce înseamnă evidenţa documentelor contabile și activităților financiare ale asociaţiei dumneavoastră, conform reglementărilor legale.</p>
              </div>
              <div className={'servicii-list'}>
                <div> <span className={'icon-check'}><i className="fal fa-check"></i></span> </div>
                <p>Buna funcționare a rețelelor tehnice și curățenia blocului locativ, sunt elemente importante care aduc confortul necesar, din acest motiv Administrarea tehnică și salubrizarea sunt parte componentă a serviciilor pe care le oferim clienților noștri.</p>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12 pe-xl-0">
              <div className="card" >

                <img src={icon_administration} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">Administrare completă</h5>
                    <p className="card-text">
                      Administrare condominiu (imobile); <br />
                      Administrare juridică (asociație); <br />
                      Administrare financiară; <br />
                      Administrare tehnică și curățenie;
                    </p>
                    <div className="btn-wepper">
                      <a href="#" className="bg-bth btn-full-width">Citește mai mult</a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={'row align-items-center pt-5'}>
            <div className="col-xl-4  col-sm-12 pe-xl-0">
              <div className="card" >
                <img src={icon_law} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Administrare juridică</h5>
                  <p className="card-text">
                    Înființarea asociației și înregistrarea condominiului; <br />
                    Administrarea contractelor și reprezentarea asociației; <br />
                    Asistență juridică.
                  </p>
                  <div className="btn-wepper">
                    <a href="#" className="bg-bth btn-full-width">Citește mai mult</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12 pe-xl-0">
              <div className="card" >
                <img src={icon_financial} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Administrare financiară</h5>
                  <p className="card-text">
                    Servicii de contabilitate; <br />
                    Servicii de intermediar la decontări; <br />
                    Servicii de cenzorat și audit.
                  </p>
                  <div className="btn-wepper">
                    <a href="#" className="bg-bth btn-full-width ">Citește mai mult</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12 pe-xl-0">
              <div className="card" >
                <img src={icon_suport} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Asistență tehnică și curățenie</h5>
                  <p className="card-text">
                    Servicii de mentenanță tehnică; <br />
                    Salubrizarea scărilor; <br />
                    Servicii curățenie și amenajare a terenului aferent.
                  </p>
                  <div className="btn-wepper">
                    <a href="#" className="bg-bth btn-full-width">Citește mai mult</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className={'pay-online-section '}>
        <div className={'container'}>
          <div className="row">
            <div className="col-xl-12">
              <div className="block-contents text-center">
                <div className="section-title about-title">
                  <h2>Achita online</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className={'col-md-6 col-xs-12'}>
              <img src={illustration} />
            </div>
            <div className={'col-md-6 col-xs-12'}>
              <div className="block-contents">
                <div className="section-title">
                  <span>Cum functioneaza ?</span>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="btn-wepper">
                <a href="#" className="bg-bth btn-read-more">Citește mai mult <i className="fal fa-arrow-right"></i> </a>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className={'col-md-6 col-xs-12'}>
              <div className="block-contents">
                <div className="section-title">
                  <span>Cum functioneaza ?</span>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="btn-wepper">
                <a href="#" className="bg-bth btn-send-indicators">Prezintă indicii <i className="fal fa-arrow-right"></i></a>
              </div>
            </div>
            <div className={'col-md-6 col-xs-12'}>
              <div className="block-contents">
                <div className="section-title">
                  <span>Cum functioneaza ?</span>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="btn-wepper">
                <a href="#" className="bg-bth btn-pay-now">Achita online <i className="fal fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>

      </section>
      <footer className="footer-1 footer-wrap">
        <div className="footer-widgets-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
                <div className="single-footer-wid site_info_widget">
                  <div className="wid-title">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="contact-us">
                    <div className="single-contact-info">
                      <div className="icon">
                        <i className="fal fa-phone"></i>
                      </div>
                      <div className="contact-info">
                        <p>+373 62-00-78-00</p>
                      </div>
                    </div>
                    <div className="single-contact-info">
                      <div className="icon">
                        <i className="fal fa-envelope"></i>
                      </div>
                      <div className="contact-info">
                        <p>contacte@profadmin.md</p>
                      </div>
                    </div>
                    <div className="single-contact-info">
                      <div className="icon">
                        <i className="fal fa-map-marker-alt"></i>
                      </div>
                      <div className="contact-info">
                        <p>str Nicolae Dimo 1 <br /> or. Chisinau</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
                <div className="single-footer-wid">
                  <div className="wid-title">
                    <h3>Quick Links</h3>
                  </div>
                  <ul>
                    <li><a href="about.html">Despre noi</a></li>
                    <li><a href="services.html">Servicii</a></li>
                    <li><a href="services.html">Facturare</a></li>
                    <li><a href="services.html">Info utile</a></li>
                    <li><a href="services.html">Intrebari frecvente</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">
                <div className="single-footer-wid newsletter_widget">
                  <div className="wid-title">
                    <h3>Newsletter</h3>
                  </div>
                  <div className="newsletter_box">
                    <form action="#">
                      <i className="fal fa-envelope"></i>
                      <input type="email" placeholder="Enter email address" required />
                        <button className="submit-btn" type="submit">Subscribe Now <i className="fas fa-paper-plane"></i></button>
                    </form>
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-cta-wrapper">
          <div className="container">
            <div className="footer-cta-bg-wrapper">
              <div className="row justify-content-around align-items-center">
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="footer-logo">
                    <a href="/">
                      <img src={bottomLogo} alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 ps-lg-0 offset-lg-1 col-12">
                  <div className="footer-middle-text mt-4 mb-4 mt-md-0 mb-md-0 text-white">
                    <p>Become an innovative entrepreneurial & empowered company constantly creating mission to be a world class construction company.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="footer-social-icon ms-md-5 text-lg-md-end">
                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                    <a href="#"><i className="fab fa-twitter"></i></a>
                    <a href="#"><i className="fab fa-instagram"></i></a>
                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-12 text-center text-md-start">
                <div className="copyright-info">
                  <p>© 2024 Copyright By ProfAdmin. All Rights Reserved</p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="footer-menu mt-3 mt-md-0 text-center text-md-end">
                  <ul>
                    <li><a href="#">Teams & Conditions</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
