import {useEffect, useState} from 'react';
import './modal.css';
import ContentModal from "./ContentModal";

const ModalWindow = ({data, setClose}) => {

    const [show, setShow] = useState(false);
    const {e, keyName} = data;

    const handleClose = () => {
        setShow(false)
        setClose()
    };
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (keyName && keyName.length) {
            setShow(true);
        }
    }, [keyName]);

    if (!show) return null;

    return (
        <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" aria-modal="true" className="fade modal show" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/*<div className="modal-title h4"  dangerouslySetInnerHTML={{__html: ContentModal[keyName+'_title']}}></div>*/}
                            <div className="modal-title h4 w-100">
                                {/* <ContentModal keyName={keyName+'_title'} /> */}
                                <h6 className="text-center"><ContentModal keyName={keyName+'_title'} /></h6>
                            </div>
                            <button type="button" onClick={handleClose} className="btn-close"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ContentModal keyName={keyName} />
                            {/*<div className={'modalContent'} dangerouslySetInnerHTML={{__html: ContentModal[keyName]}} />*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}

export default ModalWindow;
