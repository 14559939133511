import {Accordion} from "react-bootstrap";
import {ReactComponent as Xmark} from "../../assets/xmark.svg";
import {ReactComponent as Check2_square} from "../../assets/check2-square.svg";

let contentModalArr = {
  contentModal1: "",
  contentModal2: "",
  contentModal3: "",
  contentModal4: "",
  contentModal5: "",
  contentModal6: "",
  contentModal7: "",
  contentModal8: "",
  contentModal9: "",
};

contentModalArr.contentModal1_title = () => "ADMINISTRARE COMPLETĂ";
contentModalArr.contentModal1 = () => (
  <>
    <ol className="alternating-colors">
      <li>
        Administrarea transparentă și profesionistă a complexului de bunuri
        imobile &icirc;n condominiu;
      </li>
      <li>Evidența documentaţiei tehnice;</li>
      <li>
        Semnarea și monitorizarea contractelor cu furnizorii/prestatorii de
        servicii publice (S.A. "Apă-Canal" Chişinău, &Icirc;.C.S. &ldquo;Red
        Union Fenosa&rdquo; S.A., deservirea tehnica lift, Regia
        Autosalubritate, sistemului interfonic, antidiciar, ș.a), &icirc;n cazul
        imposibilității tehnice contractării individuale, inclusiv altor
        contracte economice, &icirc;n scopul asigurării condominiului cu
        servicii publice;
      </li>
      <li>
        Efectuarea reviziei tehnice a blocurilor locative şi, &icirc;n temeiul
        datelor reviziei, &icirc;ntocmirea listei lucrărilor necesare pentru
        reparaţia blocului locativ, elementelor constructive, reţelelor și
        sistemelor inginerești;
      </li>
      <li>
        &icirc;nfăptuirea acţiunilor ce ţin de protecţia muncii şi tehnica
        securităţii;
      </li>
      <li>
        Pregătirea blocurilor locative pentru exploatare &icirc;n sezonul de
        &icirc;ncălzire;
      </li>
      <li>
        Organizarea Adunărilor Generale și ședințelor Consiliului de
        administrare la Asociației;
      </li>
      <li>
        Pvidența și executarea Hotăr&icirc;rilor, potrivit legislaţiei &icirc;n
        vigoare
      </li>
    </ol>
  </>
);
contentModalArr.contentModal2_title = () => "ECONOM";
contentModalArr.contentModal2 = () => (
  <>
    {/* <h6 className="text-center mb-4">Complex</h6> */}
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>INTERMEDIAR la decontări</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Evidența consumurilor și decontărilor cu furnizorii/prestatorii de servicii;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Evidența proprietarilor, colectarea și prelucrarea datelor contoarelor comune și/sau individuale, monitorizarea achitărilor, eliberarea documentelor de plată și a certificatelor confirmative;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Calculul și repartizarea cheltuielilor de bloc și altor fonduri, calculul penalităților aplicate și evidența încasărilor, în strictă conformitate cu legislația în vigoare și Hotărârile Asociației – Convenția de facturare;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Repartizarea bonurilor de plată în cutiile poștale, inclusiv online la poșta electronică a locatarilor;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Organizarea plăților online a serviciilor publice și evidenței consumurilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>SALUBRIZAREA blocurilor, curților, spațiilor verzi</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Curățenia uscată/umedă a blocurilor și terenului aferent, potrivit programului aprobat;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Salubrizarea subsolurilor, acoperișurilor, terenurilor de joc pentru copii, tomberoanelor și platformei de colectarea gunoiului;
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Servicii de grădinărit (întreținerea arborilor, arbuștilor și plantelor, stropirea și tunderea gazonului, etc.);
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Reparația şi vopsirea detaliilor curții și a terenului de joc pentru copii - o dată pe an;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Deszăpezirea intrării în scara blocului, părții carosabile, prelucrarea cu material antiderapant (sare, nisip);
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Curățenia după constructor;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Curățenia generală a tuturor spațiilor comune și terenului aferent;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>MENTENANȚĂ TEHNICĂ</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Lucrători tehnici delegați, în scopul monitorizării situației tehnice a condominiului, elementelor constructive, rețelelor și sistemelor inginerești comune (apă, canalizare, electricitate);
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Elaborarea planului de întreținere, reparație, modernizare și reconstrucție a părților comune;
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Suport tehnic si logistic 24 /7, pentru intervenții urgente (avarii);
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Pregătirea condominiului pentru exploatare în sezonul de toamnă-iarnă;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Efectuarea lucrărilor de mentenanță a proprietății comune (robinetelor, ventilelor, supapelor cu schimbarea garniturilor, schimbul becurilor electrice, dispozitivelor de siguranță, întrerupătoarelor automate, etc.), în scopul întreținerii în stare funcțională a instalațiilor și sistemelor inginerești în locurile de uz comun;
        </li>
      </ul>
    </div>
  </>
);
contentModalArr.contentModal3_title = () => "STANDART";
contentModalArr.contentModal3 = () => (
  <>
    {/* <h6 className="text-center mb-4">Complex</h6> */}
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>SERVICII ADMINISTRATIVE</b>
      </div>
      <ul className="complex-list">
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Administrator delegat
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Administrarea Asociației și bunurilor comune, controlul personalului antrenat în administrarea condominiului;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Reprezentarea în relațiile cu Operatorii de servicii;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Activități de secretariat, înregistrarea și soluționarea reclamațiilor proprietarilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>ADMINISTRARE financiar-contabilă</b>
      </div>
      <ul className="complex-list">
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Contabil delegat
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Organizarea și conducerea evidenței financiar-contabilă a Asociației;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Efectuarea operațiunilor financiar-bancare ale Asociației;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>INTERMEDIAR la decontări</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Evidența consumurilor și decontărilor cu furnizorii/prestatorii de servicii;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Evidența proprietarilor, colectarea și prelucrarea datelor contoarelor comune și/sau individuale, monitorizarea achitărilor, eliberarea documentelor de plată și a certificatelor confirmative;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Calculul și repartizarea cheltuielilor de bloc și altor fonduri, calculul penalităților aplicate și evidența încasărilor, în strictă conformitate cu legislația în vigoare și Hotărârile Asociației – Convenția de facturare;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Repartizarea bonurilor de plată în cutiile poștale, inclusiv online la poșta electronică a locatarilor;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Organizarea plăților online a serviciilor publice și evidenței consumurilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>ASISTENȚĂ JURIDICĂ</b>
      </div>
      <ul className="complex-list">
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Jurist delegat
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Reprezentarea Asociației, medierea conflictelor și ajustarea activității Asociației la cadrul legal în vigoare;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Asistență juridică la înregistrarea Asociației și a condominiului (bunurilor imobile);
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Elaborarea regulamentelor de ordin intern, și altor acte ce țin de activitatea Asociației;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Monitorizarea rău platnicilor și pregătirea acțiunilor în instanța de judecată;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Asistență la organizarea Adunărilor Generale a Asociației;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Consultarea proprietarilor privind posibilitatea de a obține subvenții/compensații la plata serviciilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>SALUBRIZAREA blocurilor, curților, spațiilor verzi</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Curățenia uscată/umedă a blocurilor și terenului aferent, potrivit programului aprobat;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Salubrizarea subsolurilor, acoperișurilor, terenurilor de joc pentru copii, tomberoanelor și platformei de colectarea gunoiului;
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Servicii de grădinărit (întreținerea arborilor, arbuștilor și plantelor, stropirea și tunderea gazonului, etc.);
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Reparația şi vopsirea detaliilor curții și a terenului de joc pentru copii - o dată pe an;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Deszăpezirea intrării în scara blocului, părții carosabile, prelucrarea cu material antiderapant (sare, nisip);
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Curățenia după constructor;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Curățenia generală a tuturor spațiilor comune și terenului aferent;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>MENTENANȚĂ TEHNICĂ</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Lucrători tehnici delegați, în scopul monitorizării situației tehnice a condominiului, elementelor constructive, rețelelor și sistemelor inginerești comune (apă, canalizare, electricitate);
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Elaborarea planului de întreținere, reparație, modernizare și reconstrucție a părților comune;
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Suport tehnic si logistic 24 /7, pentru intervenții urgente (avarii);
        </li>
        <li>
          {/* <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" /> */}
          <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" />
          Pregătirea condominiului pentru exploatare în sezonul de toamnă-iarnă;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Efectuarea lucrărilor de mentenanță a proprietății comune (robinetelor, ventilelor, supapelor cu schimbarea garniturilor, schimbul becurilor electrice, dispozitivelor de siguranță, întrerupătoarelor automate, etc.), în scopul întreținerii în stare funcțională a instalațiilor și sistemelor inginerești în locurile de uz comun;
        </li>
      </ul>
    </div>
  </>
);

contentModalArr.contentModal4_title = () => "COMPLEX";
contentModalArr.contentModal4 = () => (
  <>
    {/* <h6 className="text-center mb-4">Complex</h6> */}
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>SERVICII ADMINISTRATIVE</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Administrator delegat
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Administrarea Asociației și bunurilor comune, controlul personalului antrenat în administrarea condominiului;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Reprezentarea în relațiile cu Operatorii de servicii;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Activități de secretariat, înregistrarea și soluționarea reclamațiilor proprietarilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>ADMINISTRARE financiar-contabilă</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Contabil delegat
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Organizarea și conducerea evidenței financiar-contabilă a Asociației;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Efectuarea operațiunilor financiar-bancare ale Asociației;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>INTERMEDIAR la decontări</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Evidența consumurilor și decontărilor cu furnizorii/prestatorii de servicii;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Evidența proprietarilor, colectarea și prelucrarea datelor contoarelor comune și/sau individuale, monitorizarea achitărilor, eliberarea documentelor de plată și a certificatelor confirmative;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Calculul și repartizarea cheltuielilor de bloc și altor fonduri, calculul penalităților aplicate și evidența încasărilor, în strictă conformitate cu legislația în vigoare și Hotărârile Asociației – Convenția de facturare;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Repartizarea bonurilor de plată în cutiile poștale, inclusiv online la poșta electronică a locatarilor;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Organizarea plăților online a serviciilor publice și evidenței consumurilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>ASISTENȚĂ JURIDICĂ</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Jurist delegat
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Reprezentarea Asociației, medierea conflictelor și ajustarea activității Asociației la cadrul legal în vigoare;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Asistență juridică la înregistrarea Asociației și a condominiului (bunurilor imobile);
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Elaborarea regulamentelor de ordin intern, și altor acte ce țin de activitatea Asociației;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Monitorizarea rău platnicilor și pregătirea acțiunilor în instanța de judecată;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Asistență la organizarea Adunărilor Generale a Asociației;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Consultarea proprietarilor privind posibilitatea de a obține subvenții/compensații la plata serviciilor;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>SALUBRIZAREA blocurilor, curților, spațiilor verzi</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Curățenia uscată/umedă a blocurilor și terenului aferent, potrivit programului aprobat;
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Salubrizarea subsolurilor, acoperișurilor, terenurilor de joc pentru copii, tomberoanelor și platformei de colectarea gunoiului;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Servicii de grădinărit (întreținerea arborilor, arbuștilor și plantelor, stropirea și tunderea gazonului, etc.);
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Reparația şi vopsirea detaliilor curții și a terenului de joc pentru copii - o dată pe an;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Deszăpezirea intrării în scara blocului, părții carosabile, prelucrarea cu material antiderapant (sare, nisip);
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Curățenia după constructor;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Curățenia generală a tuturor spațiilor comune și terenului aferent;
        </li>
      </ul>
    </div>
    <div className="complex-content-item mb-4">
      <div className="complex-title">
        <b>MENTENANȚĂ TEHNICĂ</b>
      </div>
      <ul className="complex-list">
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Lucrători tehnici delegați, în scopul monitorizării situației tehnice a condominiului, elementelor constructive, rețelelor și sistemelor inginerești comune (apă, canalizare, electricitate);
        </li>
        <li>
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Elaborarea planului de întreținere, reparație, modernizare și reconstrucție a părților comune;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Suport tehnic si logistic 24 /7, pentru intervenții urgente (avarii);
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          {/* <Xmark style={{fill: 'red', stroke: 'red'}} className="complex-list-icon" /> */}
          Pregătirea condominiului pentru exploatare în sezonul de toamnă-iarnă;
        </li>
        <li>
          <Check2_square style={{fill: 'green', stroke: 'green'}} className="complex-list-icon" />
          Efectuarea lucrărilor de mentenanță a proprietății comune (robinetelor, ventilelor, supapelor cu schimbarea garniturilor, schimbul becurilor electrice, dispozitivelor de siguranță, întrerupătoarelor automate, etc.), în scopul întreținerii în stare funcțională a instalațiilor și sistemelor inginerești în locurile de uz comun;
        </li>
      </ul>
    </div>
  </>
);

contentModalArr.contentModal5_title = () => "Intermediar la decontări";
contentModalArr.contentModal5 = () => (
  <p>
    <b>Serviciile de Intermediar la Decontări</b> prestate de PROFADMIN SRL asigură: gestionarea bazelor de date în sisteme informaționale automatizate, distribuirea consumurilor/cotelor de contribuție per unitate (imobil), emiterea facturilor, încasarea plăților prin sisteme digitalizate.
  </p>
);

contentModalArr.contentModal6_title = () => "Anunțuri";
contentModalArr.contentModal6 = () => (
  <p>
    Scopul lansării noii versiuni a paginii oficiale <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a>, este de a oferi clienților o informație mult mai amplă despre serviciile prestate de PROFADMIN, precum și informații de suport ce vor facilita accesarea serviciilor noastre. <br />
    Totodată, prin noua versiune a paginii WEB, a fost creat și pus aplicare un nou <b>instrument de plată electronic</b> al facturilor/documentelor de plată și poate fi accesat direct pe <a href="/factura" target="_blank" className="go-to-factura-link"><i>pagina oficială</i></a>. Acest instrument va oferi posibilitatea plătitorilor de a efectua plățile <b>online/distant/non-stop</b> cu: portofele electronice/conturi Paynet, carduri bancare VISA și MasterCard (naționale și internaționale).<br />
    De asemenea, a fost creat și instrumentul prin care se oferă plătitorilor posibilitatea de a introduce indicii contoarelor <b>online/distant/non-stop</b>, fără intermediari și fără necesitatea de a efectua alte operațiuni.
  </p>
);

contentModalArr.contentModal7_title = () => "Articole";
contentModalArr.contentModal7 = () => (
  <p></p>
);

contentModalArr.contentModal8_title = () => "Legislație";
contentModalArr.contentModal8 = () => (
  <p></p>
);

contentModalArr.contentModal9_title = () => "Întrebări frecvente";
contentModalArr.contentModal9 = () => (
  <div className="questions-section">
    <div className="col-md-12 col-xs-12 d-flex flex-column justify-content-between">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span>
              Cum pot recepționa factura pe e-mail?
            </span>
            <div className="custom-accordion-button-icon"></div>
          </Accordion.Header>
          <Accordion.Body>
            Pentru a recepționa factura pe e-mail este necesar de a expedia o solicitare în adresa
            noastră, unde să fie indicate datele de identificare ale solicitantului și cod ID al unității
            (imobilului) pentru care se face solicitarea.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <span>
              Cum pot fi achitate facturile de plată?
            </span>
            <div className="custom-accordion-button-icon"></div>
          </Accordion.Header>
          <Accordion.Body>
            Lunar, după emiterea facturilor/documentelor de plată plătitorilor, acestea sânt disponibile
            în sistemele de plăți pe întreg teritoriul Republicii Moldova, precum și prin intermediul sistemelor de
            plăți internaționale „visa/mastercard”. <br />
            Achitarea serviciilor poate fi efectuată în numerar sau prin virament:
            <ul className="utils-info-list mb-2">
              <li>
                prin accesarea butonului Achită Online
              </li>
              <li>
                prin instituțiile bancare MAIB, Moldinconbank, Victoriabank, Oficiul Poștal - la ghișeu sau prin internet Banking;
              </li>
              <li>
                prin terminale și sisteme de plăți electronice (Paynet, Bpay, Runpay, Paymaster, oplata.md).
                Pentru efectuarea plăților este necesar să selectați „servicii comunale” - operator
                „PROFADMIN” și să introduceți „codul - ID Plătitor” din documentul de plată.
              </li>
            </ul>
            <i><strong style={{color: '#e35f21'}}>ATENȚIE!</strong> Facturile/documente de plată, urmează a fi achitate în termenii de plată indicați în acestea. Plățile efectuate după
              termenii de plată indicați vor fi reflectate în luna următoare celei în care au fost efectuate.</i>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <span>
              Ce se întâmplă în cazul neachitării în termen a facturilor de plată?
            </span>
            <div className="custom-accordion-button-icon"></div>
          </Accordion.Header>
          <Accordion.Body>
            <b>Termenul</b> limită de plată al <b>facturilor</b> este indicat în acestea. În cazul plăților efectuate după termenul limită de plată, sumele respective vor fi reflectate în luna următoare celei în care au fost efectuate. <br />
            <i className="mt-2 d-block">
              <strong style={{color: '#e35f21'}}>ATENȚIE!</strong> <b>Nerespectarea</b> termenului de plată indicat, poate genera blocaje în activitatea normală a <b>Administratorului/Gestionarului</b> condominiului, inclusiv prin sistarea serviciilor de către Operatori/Furnizori și/sau aplicarea penalităților de întârziere.
              De asemenea, <b>Nerespectarea</b> termenului de plată indicat, poate atrage după sine aplicarea sancțiunilor și penalizărilor de întârziere, după caz poate implica cheltuieli suplimentare (cum ar fi cheltuieli de judecată, de executare silită, etc.).
            </i>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <span>
              Care este necesitatea prezentării indicilor echipamentelor de măsurare (contoarelor) individuale?
            </span>
            <div className="custom-accordion-button-icon"></div>
          </Accordion.Header>
          <Accordion.Body>
            <b>Evidența/facturarea</b> consumurilor individuale contorizate se efectuează în baza indicilor echipamentelor de măsurare (contoarelor) individuale montate la nivel de unitate. <b>Nerespectarea</b> indicilor echipamentelor de măsurare (contoarelor) individuale duce la determinarea consumurilor pe baza normativelor și/sau a consumurilor medii, diferite decât consumurile reale, ceea ce poate implica penalizări/consumuri suplimentare.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <span>
              Ce trebuie să fac în cazul expirării termenului verificării metrologice a contorului de evidență a consumului individual?
            </span>
            <div className="custom-accordion-button-icon"></div>
          </Accordion.Header>
          <Accordion.Body>
            În cazul expirării termenului de verificare metrologică a echipamentelor de măsurare (contoarelor) individuale, proprietarul este obligată să anunțe <b>Administratorul/Gestionarul</b> fondului locativ (conform procedurilor interne), în scopul demontării și verificării echipamentelor conform actelor normative în vigoare. Termenul de verificare metrologică este indica în <b>facturile</b> în tabelul dedicat „Indicii Contorului”.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  </div>
);


const ContentModal = ({keyName}) => {
  if (!contentModalArr[keyName]) return <></>;

  const Content = contentModalArr[keyName];
  return (
    <>
      <Content />
    </>
  );
};

export default ContentModal;
