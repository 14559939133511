/* eslint-disable no-unused-expressions */
import React from 'react';
import md5 from "md5";
import CryptoJS from "crypto-js";

export class PaynetRequest {
    constructor() {
        this.ExternalDate;
        this.ExternalID;
        this.Currency = 498;
        this.Merchant;
        this.LinkSuccess;
        this.LinkCancel;
        this.ExpiryDate;
        this.Lang;
        this.Service = [];
        this.Products = [];
        this.Customer = [];
        this.Amount;
    }
}

export class PaynetResult {
    constructor() {
        this.Code;
        this.Message;
        this.Data;
    }

    IsOk() {
        return this.Code === PaynetCode.SUCCESS;
    }
}

export class PaynetCode {
    static SUCCESS = 0;
    static TECHNICAL_ERROR = 1;
    static DATABASE_ERROR = 2;
    static USERNAME_OR_PASSWORD_WRONG = 3;
    static CONNECTION_ERROR = 12;
}
export default class PaynetEcomAPI {
    constructor(merchant_code = null, merchant_secret_key = null, merchant_user = null, merchant_user_password = null) {
        this.API_VERSION = "Version 1.2";
        this.merchant_code = merchant_code;
        this.merchant_secret_key = merchant_secret_key;
        this.merchant_user = merchant_user;
        this.merchant_user_password = merchant_user_password;
        this.PAYNET_BASE_UI_URL = "https://paynet.md/Acquiring/SetEcom"; // production link: https://paynet.md/acquiring/setecom
        this.PAYNET_BASE_UI_SERVER_URL = "https://paynet.md/acquiring/getecom"; // production link: https://paynet.md/acquiring/getecom
        this.PAYNET_BASE_API_URL = 'https://paynet.md:4448'; // production link: https://paynet.md:4446
        this.EXPIRY_DATE_HOURS = 4; //  hours
        this.ADAPTING_HOURS = 1; //  hours
        this.api_base_url = this.PAYNET_BASE_API_URL;
    }
    Version() {
        return this.API_VERSION;
    }
    TokenGet(addHeader = false) {
        const path = '/auth';
        const params = {
            'grant_type': 'password',
            'username': this.merchant_user,
            'password': this.merchant_user_password
        };
        const tokenReq = this.callApi(path, 'POST', params);
        const result = new PaynetResult();
        if (tokenReq.Code == PaynetCode.SUCCESS) {
            if ('access_token' in tokenReq.Data) {
                result.Code = PaynetCode.SUCCESS;
                if (addHeader)
                    result.Data = ["Authorization: Bearer " + tokenReq.Data['access_token']];
                else
                    result.Data = tokenReq.Data['access_token'];
            } else {
                result.Code = PaynetCode.USERNAME_OR_PASSWORD_WRONG;
                if ('Message' in tokenReq.Data)
                    result.Message = tokenReq.Data['Message'];
                if ('error' in tokenReq.Data)
                    result.Message = tokenReq.Data['error'];
            }
        } else {
            result.Code = tokenReq.Code;
            result.Message = tokenReq.Message;
        }
        return result;
    }
    PaymentGet(externalID) {
        const path = '/api/Payments';
        const params = {
            'ExternalID': externalID
        };
        const tokenReq = this.TokenGet(true);
        const result = new PaynetResult();
        if (tokenReq.IsOk()) {
            const resultCheck = this.callApi(path, 'GET', null, params, tokenReq.Data);
            if (resultCheck.IsOk()) {
                result.Code = resultCheck.Code;
                if ('Code' in resultCheck.Data) {
                    result.Code = resultCheck.Data['Code'];
                    result.Message = resultCheck.Data['Message'];
                } else {
                    result.Data = resultCheck.Data;
                }
            } else
                result = resultCheck;
        } else {
            result.Code = tokenReq.Code;
            result.Message = tokenReq.Message;
        }
        return result;
    }
    FormCreate2({ api, pRequest, onSubmit, disabled, passRef }) {
        // console.log(pRequest)
        if (!pRequest.Products.length) return null;
        console.log(pRequest.Products)
        let amount = 0;
        const result = new PaynetResult();
        result.Code = PaynetCode.SUCCESS;
        let productLine = 0;
        const newServiceItem = pRequest.Service["Products"].map((item, i) => {
            const productAmount =  item['Amount'];
            amount = amount + productAmount;
            productLine++;

            return (
                <React.Fragment key={i}>
                    <input type="hidden" name={`Services[0][Products][${productLine - 1}][LineNo]`} value={`${decodeURIComponent(item['LineNo'])}`} />
                    <input type="hidden" name={`Services[0][Products][${productLine - 1}][Code]`} value={`${decodeURIComponent(item['Code'])}`} />
                    {/* <input type="hidden" name={`Services[0][Products][${productLine - 1}][UnitPrice]`} value={`${decodeURIComponent(item['UnitPrice'])}`} /> */}
                    <input type="hidden" name={`Services[0][Products][${productLine - 1}][Amount]`} value={`${decodeURIComponent(item['Amount'])}`} />
                    {/* <input type="hidden" name={`Services[0][Products][${productLine - 1}][UnitProduct]`} value={`${decodeURIComponent(item['UnitProduct'])}`} /> */}
                    <input type="hidden" name={`Services[0][Products][${productLine - 1}][Name]`} value={`${decodeURIComponent(item['Name'])}`} />
                    {/* ... other hidden input fields for each product item */}
                </React.Fragment>
            );
        });


        pRequest.Service["Amount"] = amount;
        pRequest.ExternalDate = api.ExternalDate(api.ADAPTING_HOURS);
        pRequest.ExpiryDate = api.ExpiryDateGet(api.EXPIRY_DATE_HOURS);
        const signature = api.SignatureGet(pRequest);
        return (
            <form method="POST" ref={passRef} onSubmit={onSubmit} action={api.PAYNET_BASE_UI_URL}>
                <input type="hidden" name="ExternalID" value={pRequest.ExternalID} />
                <input type="hidden" name="Services[0][Description]" value={decodeURIComponent(pRequest.Service["Description"])} />
                <input type="hidden" name="Services[0][Name]" value={decodeURIComponent(pRequest.Service["Name"])} />
                <input type="hidden" name="Services[0][Amount]" value={amount} />
                <div>{newServiceItem}</div> {/* No need for dangerouslySetInnerHTML */}
                <input type="hidden" name="Currency" value={pRequest.Currency} />
                <input type="hidden" name="Merchant" value={api.merchant_code} />
                <input type="hidden" name="Customer.Code" value={pRequest.Customer.Code} />
                <input type="hidden" name="Customer.Name" value={pRequest.Customer.Name} />
                <input type="hidden" name="Customer.Address" value={pRequest.Customer.Address} />
                {/* ... other hidden input fields (similar pattern) */}
                <input type="hidden" name="LinkUrlSuccess" value={pRequest.LinkSuccess} />
                <input type="hidden" name="LinkUrlCancel" value={pRequest.LinkCancel} />
                <input type="hidden" name="ExternalDate" value={pRequest.ExternalDate} />
                <input type="hidden" name="ExpiryDate" value={pRequest.ExpiryDate} />
                <input type="hidden" name="Signature" value={signature} />
                <input type="hidden" name="Lang" value={pRequest.Lang} />
                <input type="submit" className="small-reverse-ident-btn" value="Achita online" />
            </form>
        );
    };
    FormCreate(pRequest) {
        const result = new PaynetResult();
        result.Code = PaynetCode.SUCCESS;
        //----------------- preparing a service  ----------------------------
        let _service_name = '';
        let product_line = 0;
        let _service_item = "";
        //-------------------------------------------------------------------
        pRequest.ExpiryDate = this.ExpiryDateGet(this.EXPIRY_DATE_HOURS);
        let amount = 0;
        pRequest.Service["Products"].forEach(item => {
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][LineNo]" value="' + decodeURIComponent(item['LineNo']) + '"/>';
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][Code]" value="' + decodeURIComponent(item['Code']) + '"/>';
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][BarCode]" value="' + decodeURIComponent(item['Barcode']) + '"/>';
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][Name]" value="' + decodeURIComponent(item['Name']) + '"/>';
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][Description]" value="' + decodeURIComponent(item['Descrption']) + '"/>';
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][Quantity]" value="' + decodeURIComponent(item['Quantity']) + '"/>';
            _service_item += '<input type="hidden" name="Services[0][Products][' + product_line + '][UnitPrice]" value="' + decodeURIComponent(item['UnitPrice']) + '"/>';
            product_line++;
            amount += item['Quantity'] / 100 * item['UnitPrice'];
        });
        pRequest.Service["Amount"] = amount;
        const signature = this.SignatureGet(pRequest);
        const pp_form = '<form method="POST" action="' + this.PAYNET_BASE_UI_URL + '">' +
            '<input type="hidden" name="ExternalID" value="' + pRequest.ExternalID + '"/>' +
            '<input type="hidden" name="Services[0][Description]" value="' + decodeURIComponent(pRequest.Service["Description"]) + '"/>' +
            '<input type="hidden" name="Services[0][Name]" value="' + decodeURIComponent(pRequest.Service["Name"]) + '"/>' +
            '<input type="hidden" name="Services[0][Amount]" value="' + amount + '"/>' +
            _service_item +
            '<input type="hidden" name="Currency" value="' + pRequest.Currency + '"/>' +
            '<input type="hidden" name="Merchant" value="' + this.merchant_code + '"/>' +
            '<input type="hidden" name="Customer.Code"   value="' + decodeURIComponent(pRequest.Customer['Code']) + '"/>' +
            '<input type="hidden" name="Customer.Name"   value="' + decodeURIComponent(pRequest.Customer['Name']) + '"/>' +
            '<input type="hidden" name="Customer.Address"   value="' + decodeURIComponent(pRequest.Customer['Address']) + '"/>' +
            '<input type="hidden" name="Payer.Email"   value="v.bragari@ggg.md"/>' +
            '<input type="hidden" name="Payer.Name"   value="Oleg"/>' +
            '<input type="hidden" name="Payer.Surname"   value="Stoianov"/>' +
            '<input type="hidden" name="Payer.Mobile"   value="37360000000"/>' +
            '<input type="hidden" name="ExternalDate" value="' + decodeURIComponent(this.ExternalDate()) + '"/>' +
            '<input type="hidden" name="LinkUrlSuccess" value="' + decodeURIComponent(pRequest.LinkSuccess) + '"/>' +
            '<input type="hidden" name="LinkUrlCancel" value="' + decodeURIComponent(pRequest.LinkCancel) + '"/>' +
            '<input type="hidden" name="ExpiryDate"   value="' + decodeURIComponent(pRequest.ExpiryDate) + '"/>' +
            '<input type="hidden" name="Signature" value="' + signature + '"/>' +
            '<input type="hidden" name="Lang" value="' + pRequest.Lang + '"/>' +
            '<input type="submit" class="small-reverse-ident-btn" value="Achita online" />' +
            '</form>';
        result.Data = pp_form;
        return result;
    }
    PaymentReg(pRequest) {
        let path = '/api/Payments/Send';
        pRequest.ExpiryDate = this.ExpiryDateGet(this.EXPIRY_DATE_HOURS);
        //------------- calculating total amount
        pRequest.Service[0]['Amount'] = 0;
        pRequest.Service[0]['Products'].forEach(item => {
            pRequest.Service[0]['Amount'] += (item['Quantity'] / 100) * item['UnitPrice'];
        });
        let params = {
            'Invoice': pRequest.ExternalID,
            'MerchantCode': this.merchant_code,
            'LinkUrlSuccess': pRequest.LinkSuccess,
            'LinkUrlCancel': pRequest.LinkCancel,
            'Customer': pRequest.Customer,
            'Payer': pRequest.Customer,
            'Currency': 498,
            'ExternalDate': this.ExternalDate(),
            'ExpiryDate': this.ExpiryDateGet(this.EXPIRY_DATE_HOURS),
            'Services': pRequest.Service,
            'Lang': pRequest.Lang
        };
        let tokenReq = this.TokenGet(true);
        let result = new PaynetResult();
        if (tokenReq.IsOk()) {
            const resultCheck = this.callApi(path, 'POST', params, [], tokenReq.Data);
            if (resultCheck.IsOk()) {
                result.Code = resultCheck.Code;
                if ('Code' in resultCheck.Data) {
                    result.Code = resultCheck.Data['Code'];
                    result.Message = resultCheck.Data['Message'];
                } else {
                    let pp_form = '<form method="POST" action="' + this.PAYNET_BASE_UI_SERVER_URL + '">' +
                        '<input type="hidden" name="operation" value="' + decodeURIComponent(resultCheck.Data['PaymentId']) + '"/>' +
                        '<input type="hidden" name="LinkUrlSucces" value="' + decodeURIComponent(pRequest.LinkSuccess) + '"/>' +
                        '<input type="hidden" name="LinkUrlCancel" value="' + decodeURIComponent(pRequest.LinkCancel) + '"/>' +
                        '<input type="hidden" name="ExpiryDate"   value="' + decodeURIComponent(pRequest.ExpiryDate) + '"/>' +
                        '<input type="hidden" name="Signature" value="' + resultCheck.Data['Signature'] + '"/>' +
                        '<input type="hidden" name="Lang" value="' + pRequest.Lang + '"/>' +
                        '<input type="submit" value="GO to a payment gateway of paynet" />' +
                        '</form>';
                    result.Data = pp_form;
                }
            } else {
                result = resultCheck;
            }
        } else {
            result.Code = tokenReq.Code;
            result.Message = tokenReq.Message;
        }
        return result;
    }
    callApi(path, method = 'GET', params = {}, query_params = {}, headers = []) {
        let result = new PaynetResult();
        let url = this.api_base_url + path;
        if (Object.keys(query_params).length > 0) {
            const queryString = new URLSearchParams(query_params).toString();
            url += '?' + queryString;
        }
        const options = {
            method: method,
            headers: headers,
            body: method !== 'GET' ? new URLSearchParams(params) : null
        };
        fetch(url, options)
            .then(response => response.json())
            .then(json => {
                result.Data = json;
                result.Code = PaynetCode.SUCCESS;
            })
            .catch(error => {
                result.Message = error.message;
                result.Code = PaynetCode.CONNECTION_ERROR;
            });
        return result;
    }

    ExpiryDateGet(addHours) {
        const date = new Date();
        date.setHours(date.getHours() + addHours);
        return date.toISOString().split('.')[0];
    }

    ExternalDate(addHours = this.ADAPTING_HOURS) {
        const date = new Date();
        date.setHours(date.getHours() + addHours);
        return date.toISOString().split('.')[0];
    }

    SignatureGet(request) {
       // console.log('SignatureGet', request)
        let _sing_raw = request.Currency;
        _sing_raw += request.Customer.Address + request.Customer.Code + request.Customer.Name;
        _sing_raw += request.ExpiryDate + String(request.ExternalID) + this.merchant_code;
        _sing_raw += request.Service.Amount + request.Service.Name + request.Service.Description;
        _sing_raw += this.merchant_secret_key;
        const hash = CryptoJS.MD5(_sing_raw); // Use MD5 (consider SHA-256 for production)
        const base64Signature = CryptoJS.enc.Base64.stringify(hash);

        //let md5Hash = crypto.createHash('md5').update(_sing_raw).digest('hex');
        return base64Signature
    }

    get(name) {
        return this[name] ?? null;
    }
}
