import React, { useState } from "react";
import { API_URL } from "../../constants";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const ContactModal = ({ show, handleClose }) => {
  const [inputs, setInputs] = useState({});
  const [result, setResult] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleInputs = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    try {
      axios.post(API_URL + "send-email", inputs);
      setResult("success");
      // onClose()
    } catch (e) {
      console.log(e);
      setResult("error");
    }
    setShowToast(true);
  };
  const onClose = () => {
    setInputs({});
    handleClose();
  };

  if (!show) return null;
  
  return (
    <>
      <div className="fade modal-backdrop show"></div>
      <div
        role="dialog"
        aria-modal="true"
        className="fade modal show"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">Contact</div>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={sendEmail}
                className="contact2-form validate-form p-4"
              >
                <span className="contact2-form-title">Întreabă-ne</span>
                <div
                  className="wrap-input2 validate-input"
                  data-validate="Name is required"
                >
                  <input
                    className={`input2 ${inputs.name ? "has-val" : ""}`}
                    type="text"
                    name="name"
                    onChange={handleInputs}
                  />
                  <span className="focus-input2" data-placeholder="Nume"></span>
                </div>
                <div
                  className="wrap-input2 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    className={`input2 ${inputs.email ? "has-val" : ""}`}
                    type="text"
                    name="email"
                    onChange={handleInputs}
                  />
                  <span
                    className="focus-input2"
                    data-placeholder="Email"
                  ></span>
                </div>
                <div
                  className="wrap-input2 validate-input"
                  data-validate="Message is required"
                >
                  <textarea
                    className={`input2 ${inputs.message ? "has-val" : ""}`}
                    name="message"
                    onChange={handleInputs}
                  ></textarea>
                  <span
                    className="focus-input2"
                    data-placeholder="Mesaj"
                  ></span>
                </div>
                <div className="container-contact2-form-btn">
                  <div className="wrap-contact2-form-btn">
                    <div className="contact2-form-bgbtn"></div>
                    <button type="submit" className="contact2-form-btn">
                      Trimiteți mesajul dvs
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        className="p-3 position-fixed"
        position={"top-end"}
        style={{ zIndex: 10000 }}
      >
        <Toast
          bg={result}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={true}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">
              {result === "success" ? "Succes" : "Eroare"}
            </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            <span className="text-white">
              {result === "success"
                ? "  Mesajul dvs. a fost trimis!"
                : "S-a produs o eroare în timpul trimiterii mesajului dumneavoastră!"}
            </span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ContactModal;
