import React from "react";
import {Link} from "react-router-dom";
import {Container, Col, Row, Image} from 'react-bootstrap';
import "./payment.css";
import ImageFail from "../../assets/lottie/decline.json";
import {Player} from '@lottiefiles/react-lottie-player';

const Cancel = () => {
  return (
    <Container className="payment-block">
      <div className="payment-title-block d-flex align-items-start mb-4">
        <Link className="back-to-factura-btn">
          <i className="fa-solid fa-chevron-left"></i>
        </Link>
        <div className="w-100">
          <h2 className="text-center mb-3">
            Plata a eșuat!
          </h2>
          <p className="text-center cancel-text">Vă rugăm să verificați: dacă soldul contului cardului Visa este suficient pentru plată.</p>
        </div>
      </div>
      <Row>
        <Col md={{span: 10, offset: 1}}>
          <div className="payment-img text-center">
            <Player
              autoplay
              loop
              src={ImageFail}
              style={{height: '400px', width: 'auto'}}
            >
            </Player>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Cancel;
